@import '../../../css/variables';

.home_hero_element {
  &__container {
    position: relative;
    background-color: $color-primary;
    color: $color-tertiary;
    overflow: hidden;
    padding: 4rem;
    min-height: 70vh;
    display: flex;
    align-items: center;
  }

  &__image {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    
    img {
      object-fit: cover;
      object-position: center bottom;
      width: 100%;
      height: 100%;
    }

    @at-root .no-image & {
      img {
        display: none;
      }
    }
  }

  &__bg {
    position: absolute;
    height: 140%;
    top: -20%;
    left: 0;
    width: 100%;
    transition: ease;
    background-color: $color-primary;
    background-image: none;
    opacity: 0.5;
    pointer-events: none;
    @include bg-pos--contain-repeat;

    @at-root .bg-variant-0 & {
      opacity: 0;
    }

    @at-root .bg-variant-1 & {
      background-image: $pattern-1;
    }

    @at-root .bg-variant-2 & {
      background-image: $pattern-2;
    }

    @at-root .bg-variant-3 & {
      background-image: $pattern-3;
    }

    @at-root .bg-variant-4 & {
      background-image: $pattern-4;
    }
  }

  &__title {
    @include h1;
    font-size: 9rem;
    line-height: 1;
    text-align: center;
    margin: 0 0 50px;

    @media(max-width: 768px){
      margin-top: 200px;
      font-size: 4rem;
    }
    
    @include font-inherit;
    @include no-spacing;
  }

  &__intro {
    @include p--medium;
    transition: ease;
    color: #fff;

    
  }

}
